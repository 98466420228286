import {UserAuth} from '../custom-types';
import { DEFAULT_USER_AUTH, DEFAULT_LANGUAGE } from './Consts';
import {IExport} from "../custom-types/Export";
import { isConsentGivenFor, FunctionalityCookies } from './Cookies';

/** Return user auth from local storage value */
export const getStoredUserAuth = (): UserAuth => {
  const auth = window.sessionStorage.getItem('UserAuth');
  if (auth) {
    return JSON.parse(auth);
  }

  return DEFAULT_USER_AUTH;
};

export const setStoredUserAuth = (userAuth: UserAuth): void => {
  window.sessionStorage.setItem('UserAuth', JSON.stringify(userAuth));
};

export const removeStoredUserAuth = () => {
  window.sessionStorage.removeItem('UserAuth');
}

export const getStoredExports = (): IExport[] => {
  if (!isConsentGivenFor(FunctionalityCookies.Export)) {
    return [];
  }

  const exports = window.localStorage.getItem('Exports');
  if (exports) {
    return JSON.parse(exports);
  }

  return [];
};

export const storeNewExport = (newEntry: IExport): void => {
  if (!isConsentGivenFor(FunctionalityCookies.Export)) {
    return;
  }

  const exports = getStoredExports();
  exports.push(newEntry);
  window.localStorage.setItem('Exports', JSON.stringify(exports));
};

export const removeStoredExports = (entries: IExport[]): void => {
  const storedExports = getStoredExports();
  const idsToRemove = entries.map((exp) => {
    return exp.id;
  });

  // Only select those without matching ID
  // or any lingering exports that have expired.
  const now = new Date();
  const exports = storedExports.filter((exp: IExport) => {
    const old = exp.expires < now;
    return !idsToRemove.includes(exp.id) && !old;
  });

  window.localStorage.setItem('Exports', JSON.stringify(exports));
};

/** Return language from local storage value */
export const getStoredLanguage = (): string => {
  if (!isConsentGivenFor(FunctionalityCookies.LanguageSelect)) {
    return DEFAULT_LANGUAGE;  
  }
  
  const language = window.localStorage.getItem('userlang');
  if (language) {
    return JSON.parse(language);
  }

  return DEFAULT_LANGUAGE;
};

export const setStoredLanguage = (languageCode: string): void => {
  if (isConsentGivenFor(FunctionalityCookies.LanguageSelect)) {
    window.localStorage.setItem('userlang', JSON.stringify(languageCode));
  }
};
