import React, { FC, useContext, useState } from 'react';
import { languageContext } from '../../contexts/LanguageContext';
import TranslatedText from '../Language/TranslatedText';
import {CookieConsentModal} from "../Modal/CookieConsentModal";
import { cookieContext } from "../../contexts/CookieContext";
import {
    ALL_COOKIES,
    cookieConsentValuesString,
    prepareCookieForSaving,
    prepareRemoveConsentCookie
} from "../../utils/Cookies";

export const CookieDisclaimer: FC = () => {
    const lang = useContext(languageContext)
    const {shouldShowCookieAlert, hideCookieAlert} = useContext(cookieContext)
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCookieAccept = (acceptAll: boolean): void => {
        const cookieString = cookieConsentValuesString(ALL_COOKIES, acceptAll);
        const cookie = prepareCookieForSaving(cookieString);
        const removeCookie = prepareRemoveConsentCookie()

        // Remove any existing cookie.
        if (removeCookie) {
            document.cookie = removeCookie;
        }

        if (cookie) {
            document.cookie = cookie;
        }
        hideCookieAlert();
    }

    const openConsentModal = (): void => {
        setIsModalVisible(true)
    };

    const closeConsentModal = (): void => {
        setIsModalVisible(false)
    };

    return (
      <>
          <div id="cookie-disclaimer"
               className={`m-cookie-disclaimer js-cookie-disclaimer ${shouldShowCookieAlert ? 'is-visible' : ''}`}>
              <div role="alert"
                   className="m-cookie-disclaimer__wrapper m-alert m-alert--info m-alert--dismissable js-dismiss-alert">
                  <div className="m-cookie-disclaimer__inner">
                      <div className="m-cookie-disclaimer__inner-first">
                          <p className="m-cookie-disclaimer__text">
                              {/* eslint-disable-next-line max-len */}
                              <TranslatedText textKey={`On the website, we use necessary cookies to ensure it functions properly for you. You can withdraw your consent at any time using "Select cookies".`} />
                              <a href="/om-webbplatsen/">
                                  {lang.getTranslatedText("Read more about how we use cookies.")}
                              </a>.
                          </p>
                      </div>
                      <div className="m-cookie-disclaimer__inner-second">
                         <button id="js-accept-cookies"
                                  className="a-button a-button--is-static"
                                  onClick={() => handleCookieAccept(true)}>
                            <span className="a-button__text ">
                                <TranslatedText textKey="Accept all cookies" />
                            </span>
                          </button>
                          <button id="js-accept-necessary-cookies"
                                  className="a-button a-button--is-static u-m-x-1"
                                  onClick={() => handleCookieAccept(false)}>
                            <span className="a-button__text ">
                                <TranslatedText textKey="Only necessary cookies" />
                            </span>
                          </button>
                          <button id="open-consent-modal"
                                  className="a-button a-button--is-static"
                                  onClick={openConsentModal}>
                            <span className="a-button__text ">
                                <TranslatedText textKey="Select cookies" />
                            </span>
                          </button>
                      </div>
                  </div>
              </div>
          </div>

          {isModalVisible && (
            <CookieConsentModal modalClosed={!isModalVisible} closeModal={closeConsentModal}/>
          )}

      </>

    )
}

export default CookieDisclaimer;
