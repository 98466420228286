import React, {FC, useContext, useEffect, useState} from 'react';
import ConfirmModal from "./ConfirmModal";
import makeStyles from '@mui/styles/makeStyles';
import {fontFamilyHeading} from "../../contexts/ThemeContext";
import {Colors} from "../../utils/Consts";
import {heading} from "../../utils/Classes";
import {Typography} from "@mui/material";
import {languageContext} from "../../contexts/LanguageContext";
import Switch from "@mui/material/Switch";
import { ReactComponent as ArrowBackwards } from '@internetstiftelsen/styleguide/src/atoms/icon/arrow-backwards.svg';
import TranslatedText from "../Language/TranslatedText";
import {
  ALL_COOKIES,
  cookieConsentValuesString,
  CoookieConsentCategories,
  FunctionalityCookies, getConsentCookieValues,
  IConsentCategory,
  IFunctionsUsingCookies, isConsentGivenFor, prepareCookieForSaving, prepareRemoveConsentCookie
} from "../../utils/Cookies";
import {cookieContext} from "../../contexts/CookieContext";

const useStyles = makeStyles(() => ({
  alwaysActive: {
    color: Colors.ocean,
    float: 'right',
  },
  categoryListHeading: {
    position: 'relative',
    fontFamily: fontFamilyHeading,
    '& > button': {
      paddingLeft: '2.5rem',
    }
  },
  categorySwitch: {
    position: 'absolute',
    top: '0.62222rem',
    left: '-0.5rem',
  },
  cookieRowContainer: {
    backgroundColor: Colors.ash,
  },
  heading
}));



interface ICookieConsentModalProps {
  modalClosed: boolean;
  closeModal: () => void;
}

export const CookieConsentModal: FC<ICookieConsentModalProps> = (props: ICookieConsentModalProps) => {
  const { modalClosed, closeModal } = props
  const classes = useStyles();
  const {hideCookieAlert} = useContext(cookieContext)
  const lang = useContext(languageContext);
  const [visibleItemsForCategory, setVisibleItemsForCategory] = useState<IFunctionsUsingCookies[]>([]);
  const [expandedItemGroup, setExpandedItemGroup] = useState<FunctionalityCookies>(FunctionalityCookies.NoneSelected);
  const [showDescriptionForCategory, setShowDescriptionForCategory] = useState<number>(0);
  const [consentCategories, setConsentCategories] = useState<IConsentCategory[]>([]);

  // Set status according to saved cookie value.
  useEffect(() => {
    const initialCookies = ALL_COOKIES;
    const savedCookieValue = getConsentCookieValues();

    initialCookies.forEach((cookieCategory) => {
      let consentGiven = 0;
      cookieCategory.functionality.forEach((f, _, arr) => {
        if (isConsentGivenFor(f.func, savedCookieValue)) {
          f.accepted = true;
          consentGiven += 1;
        }

        // Check if all cookies for a category is accepted.
        if (consentGiven === arr.length) {
          cookieCategory.consentGiven = true;
        }
      })
    });

    setConsentCategories(initialCookies);
  }, [ALL_COOKIES])

  const saveCookieChoice = (): void => {
    const cookieString = cookieConsentValuesString(consentCategories, false);
    const removeCookie = prepareRemoveConsentCookie()
    const cookie = prepareCookieForSaving(cookieString);

    // Remove any existing cookie.
    if (removeCookie) {
        document.cookie = removeCookie;
    }

    if (cookie) {
      document.cookie = cookie;
    }

    hideCookieAlert()
    closeModal()
  };

  const cancelCookieChoice = (): void => {
    closeModal()
  };

  const giveConsentForItem = (funcID: FunctionalityCookies, checked: boolean): void => {
    const items = visibleItemsForCategory.map((c) => {
      if (c.func === funcID) {
        c.accepted = checked;
      }

      return c;
    });

    const allAccepted = items.filter((c) => c.accepted).length === visibleItemsForCategory.length

    setVisibleItemsForCategory(items);
    giveConsentForCategory(showDescriptionForCategory, allAccepted)
  };

  const giveConsentForCategory = (categoryID: number, checked: boolean, propagate?: boolean): void => {
    const categories = consentCategories.filter((c) => {
      if (c.ID === categoryID) {
        c.consentGiven = checked;
        if (propagate) {
          c.functionality.forEach((f) => f.accepted = checked)
        }
      }

      return true;
    });

    setConsentCategories(categories);
  }

  const showItemsForCategory = (items: IFunctionsUsingCookies[]): void => {
    setVisibleItemsForCategory(items)
  }

  const cookieRow = (leftColumn: string, rightColumn: string): React.ReactNode => (
    <div className="row u-m-b-1">
      <div className="grid-4">
        <TranslatedText textKey={leftColumn} />
      </div>
      <div className="grid-14">
        <TranslatedText textKey={rightColumn} />
      </div>
    </div>
  );

  const itemsForCategoryAccordion = (
    <>
      <div>
        <button className="a-button a-button--icon a-button--icon-left a-button--transparent"
                onClick={(): void => setVisibleItemsForCategory([])}>
          <ArrowBackwards className="icon a-button__icon" />
          <TranslatedText className="a-button__text" textKey={'Back to list'} />
        </button>
      </div>

      <div className="js-o-accordion o-accordion">
        {visibleItemsForCategory.map((item, index) => (
          <React.Fragment key={index}>
            <Typography variant="h3" className={`o-accordion__title ${classes.categoryListHeading}`}>
              <button className="js-accordion__header o-accordion__header"
                      onClick={(): void => {
                        const expandCat = expandedItemGroup !== item.func ? item.func : FunctionalityCookies.NoneSelected;
                        setExpandedItemGroup(expandCat);
                      }}
                      aria-expanded={expandedItemGroup === item.func ? 'true' : 'false'}>
                {lang.getTranslatedText(item.domain)}
              </button>
              {item.func !== FunctionalityCookies.CookieConsent && item.func !== FunctionalityCookies.DeskproCookies && (
                <Switch id={`category-switch-${item.func}`}
                        className={classes.categorySwitch}
                        checked={item.accepted}
                        onChange={(): void => giveConsentForItem(item.func, !item.accepted)} />
              )}
            </Typography>
            <div className="js-accordion__panel o-accordion__panel"
                 aria-hidden={expandedItemGroup === item.func ? 'false' : 'true'}>
              <div className="o-accordion__panel__content u-p-0">
                {item.cookieItems.map((cookie, cIndex) => (
                  <div key={cIndex} className={`u-m-y-2 u-p-2 ${classes.cookieRowContainer}`}>
                    {cookieRow('Name', cookie.name)}
                    {cookieRow('Domain', item.domain)}
                    {cookieRow('Duration', cookie.expiry)}
                    {cookie.description !== '' && cookieRow('Description', cookie.description)}
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </>
  );

  const categoryAccordion = (
    <div className="js-o-accordion o-accordion">
      {consentCategories.map((c, index)=> (
        <React.Fragment key={index}>
          <Typography variant="h3" className={`o-accordion__title ${classes.categoryListHeading}`}>
            <button className="js-accordion__header o-accordion__header"
                    onClick={(): void => {
                      const expandCat = showDescriptionForCategory !== c.ID ? c.ID : 0;
                      setShowDescriptionForCategory(expandCat);
                    }}
                    aria-expanded={showDescriptionForCategory === c.ID ? 'true' : 'false'}>
              {lang.getTranslatedText(c.header)}
              {c.category === CoookieConsentCategories.Mandatory && (
                <span className={classes.alwaysActive}>{lang.getTranslatedText('Always active')}</span>
              )}
            </button>
            {c.category !== CoookieConsentCategories.Mandatory && (
              <Switch id={`category-switch-${c.ID}`}
                      className={classes.categorySwitch}
                      checked={c.consentGiven}
                      onChange={(): void => giveConsentForCategory(c.ID, !c.consentGiven, true)} />
            )}
          </Typography>
          <div className="js-accordion__panel o-accordion__panel"
               aria-hidden={showDescriptionForCategory === c.ID ? 'false' : 'true'}>
            <div className="o-accordion__panel__content">
              <p>
                <TranslatedText textKey={c.description} />
              </p>
              <span className="u-link" onClick={(): void => showItemsForCategory(c.functionality)}>
                {lang.getTranslatedText('Information about cookies')}
              </span>
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );

  return (
    <ConfirmModal closed={modalClosed}
                  hasForm={false}
                  modalTitle={lang.getTranslatedText('Choose what information we collect')}
                  withLoader={false}
                  confirmButtonText={lang.getTranslatedText('Save')}
                  onConfirm={saveCookieChoice}
                  confirmButtonDisabled={false}
                  onCancel={cancelCookieChoice}>
      <>
        {!visibleItemsForCategory.length && (
          <>
            <p>
              {/* eslint-disable-next-line max-len */}
              <TranslatedText textKey='To improve the website and make your experience better we use cookies. A cookie is a small text file that the website you are visiting asks to be stored on your computer, mobile or tablet for a predetermined period of time.' />
            </p>
            <h4>{lang.getTranslatedText('Decide what you want to share')}</h4>
            <p>
              {/* eslint-disable-next-line max-len */}
              <TranslatedText textKey='We respect your right to privacy, therefore, you are able to choose the type of cookies you allow. Click on the different categories to read more and select the type of cookies you accept. Note, if you block certain types of cookies, this can negatively affect your experience using the website and strictly necessary cookies must be allowed for the website to function properly.' />
            </p>
          </>
        )}

        <div className="js">
          {visibleItemsForCategory.length ? itemsForCategoryAccordion : categoryAccordion}
        </div>
      </>
    </ConfirmModal>
  )
}
