import React, {FC, useContext, useState} from "react";
import TranslatedText from "../Language/TranslatedText";

/** Images */
import CertLogo from "../../images/ISO_27001_2013_black_TM.svg"

/** Context */
import {languageContext} from "../../contexts/LanguageContext";
import { CookieConsentModal } from "../Modal/CookieConsentModal";

const Footer: FC = () => {
  const lang = useContext(languageContext);
  const [isCookieModalVisible, setIsCookieModalVisible] = useState(false);
  
  const PostalAddressString = lang.getTranslatedText("Postal address stiftelsen");
  const OnlineAddressString = lang.getTranslatedText("Online address stiftelsen");
  const StiftelseName = lang.getTranslatedText("footer_site1_h");
  const translatedGridItems = [
    {
      href: 'https://internetstiftelsen.se',
      title: lang.getTranslatedText('footer_site1_h'),
      text: lang.getTranslatedText('footer_site1_desc'),
      imgSrc: 'https://static.internetstiftelsen.se/images/logotypes/internetstiftelsen.svg',
    },
    {
      href: 'https://internetdagarna.se',
      title: lang.getTranslatedText('footer_site2_h'),
      text: lang.getTranslatedText('footer_site2_desc'),
      imgSrc: 'https://static.internetstiftelsen.se/images/logotypes/internetdagarna.svg',
    },
    {
      href: 'https://goto10.se',
      title: lang.getTranslatedText('footer_site3_h'),
      text: lang.getTranslatedText('footer_site3_desc'),
      imgSrc: 'https://static.internetstiftelsen.se/images/logotypes/goto-10.svg',
    },
    {
      href: 'https://internetmuseum.se',
      title: lang.getTranslatedText('footer_site5_h'),
      text: lang.getTranslatedText('footer_site5_desc'),
      imgSrc: 'https://static.internetstiftelsen.se/images/logotypes/internetmuseum.svg',
    },
    {
      href: 'https://digitalalektioner.se',
      title: lang.getTranslatedText('footer_site6_h'),
      text: lang.getTranslatedText('footer_site6_desc'),
      imgSrc: 'https://static.internetstiftelsen.se/images/logotypes/digitala-lektioner.svg',
    },
    {
      href: 'https://digitalalektioner.se',
      title: lang.getTranslatedText('footer_site4_h'),
      text: lang.getTranslatedText('footer_site4_desc'),
      imgSrc: 'https://static.internetstiftelsen.se/images/logotypes/internetkunskap.svg',
    },
  ];

  return (
    <>
    {isCookieModalVisible && (
      <CookieConsentModal modalClosed={!isCookieModalVisible} closeModal={() => setIsCookieModalVisible(false)}/>
    )}

    <div className="global-footer">
      <div className="site__footer">
        <div className="wrapper-fluid">
          <div className="o-footer" id="siteFooter">
            <div className="row">
              <div className="grid-18 o-footer__info">
                <div className="row">
                  <div className="grid-18 grid-lg-9 o-footer__info__freetext">
                    <p>
                      <a href="https://internetstiftelsen.se" className="o-footer__link">
                          {StiftelseName}
                      </a>
                      <TranslatedText textKey="Footer text"/>
                    </p>
                  </div>

                  <div className="grid-18 grid-lg-9 o-footer__info__contact">
                    <div className="row o-footer__info__contact__row-first">
                      <div className="grid-md-9 grid-lg-18 grid-xl-9 grid-18 o-footer__info__address1">
                        <ul className="u-list-clean o-footer__info__contact__address">
                          {PostalAddressString.split("\n").map((addressLine, index) => (
                            <React.Fragment key={index}>
                              <li className="o-footer__info__contact__address__li">{addressLine}</li>
                            </React.Fragment>
                          ))}
                        </ul>
                      </div>
                      <div className="grid-md-9 grid-lg-18 grid-xl-9 grid-18 o-footer__info__address2">
                        <ul className="u-list-clean o-footer__contact__address">
                          {OnlineAddressString.split("\n").map((line, index) => (
                            <React.Fragment key={index}>
                              <li className="o-footer__info__contact__address__li">{line}</li>
                            </React.Fragment>
                          ))}
                        </ul>
                      </div>
                    </div>

                    <div className="row o-footer__info__contact__row-second">
                      <div className="grid-19 grid-md-9 o-footer__info__ISO">
                        {/* eslint-disable-next-line max-len */}
                        <a href="https://internetstiftelsen.se/app/uploads/2019/02/certifikat-27001-ukas-sv.pdf"
                          className="o-footer__link o-footer__external-link">
                          {/* eslint-disable-next-line max-len */}
                          <img src={CertLogo}
                            className="o-footer__external-logo"
                            alt="Certifierade enligt ISO/IEC 27001:2013"></img>
                          <TranslatedText textKey="Certification text"/>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="grid-18 o-footer__logotypes">
                <div className="row">                  
                  {translatedGridItems.map((gridItem, index) => (
                    <React.Fragment key={index}>
                      <div className="o-footer__logotypes__item">
                        <a href={gridItem.href} className="o-footer__logotypes__item__link">
                          <div className="o-footer__logotypes__item__inner">
                            <div className="o-footer__logotypes__item__front">
                              <img className="o-footer__logotype" alt={gridItem.title}
                                  src={gridItem.imgSrc}></img>
                            </div>
                            <div className="o-footer__logotypes__item__back">
                              <h2>{gridItem.title}</h2>
                              <span>{gridItem.text}</span>
                            </div>
                          </div>
                        </a>
                      </div>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="u-p-y-1 o-footer__bottom-links">
                <nav>
                  <ul className="u-list-clean display-flex">
                    <li className="u-p-x-1">
                      <a href="/om-webbplatsen/" className="o-footer__link o-footer__about-link">
                        {lang.getTranslatedText('About the website')}
                      </a>
                    </li>
                    <li className="u-p-x-1">
                      <a href="/om-webbplatsen#cookies" className="o-footer__link o-footer__about-link">
                        {lang.getTranslatedText('About cookies')}
                      </a>
                    </li>
                    <li className="u-p-x-1">
                      <button className="o-footer__link o-footer__about-link" onClick={() => setIsCookieModalVisible(true)}>
                        <TranslatedText textKey="Select cookies" />
                      </button>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Footer;
